<template>
  <div class="row d-flex align-items-stretch">
    <div class="col-12 col-lg-12" >
      <div class="telecast__wrap">
        <div class="ratio ratio-16x9">
          <div class="telecast__absolute" v-if="getBackground && telecast.preview" :style="getBackground" @click="showBackground = false"></div>
          <iframe :src="telecast.link"  allowfullscreen></iframe>
        </div>
<!--        <emojis :room="telecast.id" v-if="!showBackground"></emojis>-->
      </div>
    </div>
<!--    <div class="col-12 col-lg-4" v-if="telecast.hasChat">-->
<!--      <telecast-chat :room="telecast.id"></telecast-chat>-->
<!--    </div>-->
  </div>
</template>

<script>
// import Emojis from "./Emojis";
// import TelecastChat from "./Chat/TelecastChat";
export default {
  name: "TelecastComponent",
  // components: {TelecastChat},
  props: ['telecast'],
  data: () => ({
    showBackground: true
  }),
  computed: {
    getBackground() {
      console.log(this.telecast.preview)
      if (this.showBackground) {
        return `background-image: url(${this.telecast.preview})`
      }
      return null
    }
  },
  watch: {
    telecast(newValue, oldValue) {
      if (!this.showBackground) {
        this.stopWatch(oldValue.id)
      }
      this.showBackground = true
    },
    showBackground(val) {
      if (!val) {
        this.startWatch(this.telecast.id)
      }
    }
  },
  mounted() {
    document.addEventListener("visibilitychange", this.watchClosePage);
    window.addEventListener('beforeunload', this.onClosePage)
  },
  beforeDestroy() {
    document.removeEventListener('visibilitychange', this.watchClosePage)
    window.removeEventListener('beforeunload', this.onClosePage)
  },
  methods: {
    watchClosePage() {
      if (!this.showBackground) {
        if (document.hidden) {
          this.stopWatch(this.telecast.id)
        }
        else {
          this.startWatch(this.telecast.id)
        }
      }
    },
    onClosePage() {
      this.stopWatch(this.telecast.id)
    },
    startWatch(id) {
      const payload = {
        type: 'open',
        user_id: this.$store.getters.getUser.id,
        telecast_id: id
      }
      this.$axios.patch('telecast/chat', payload)
        .then(() => {
        })
        .catch(e => {
          console.log(e)
        })
    },
    stopWatch(id) {
      const payload = {
        type: 'close',
        user_id: this.$store.getters.getUser.id,
        telecast_id: id
      }
      this.$axios.patch('telecast/chat', payload)
          .then(() => {
          })
          .catch(e => {
            console.log(e)
          })
    }
  },
}

</script>

<style scoped lang="scss">
  .telecast {
    &__wrap {
      position: relative;
      padding: 6px;
      background: #E7E7E7;
      border-radius: 10px;
    }
  }
  .telecast {
    &__absolute {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 3;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      cursor: pointer;
    }
  }
</style>
